/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "../src/styles/_variables.scss";
@import "../src/styles.scss";

@import "swiper/scss";
@import  "node_modules/swiper/swiper-bundle.min.css";
// .toast-success {
//   background-color: #51A351;
// }
// .toast-error {
//   background-color: #BD362F;
// }
// .toast-info {
//   background-color: #2F96B4;
// }
// .toast-warning {
//   background-color: #F89406;
// }
// .toolbar-background{
//   background-color: transparent;
// }
.success-toaster-class {
  --background: #51a351;
  --button-color: #ffdd00;
  --color: #fff;
  margin-top: 16px;
}
.success-toaster-class-doc {
  --background: #51a351;
  --button-color: #ffdd00;
  --color: #fff;
  margin-top: 16px;
  text-align: center;
}

.warning-toaster-class {
  --background: #f89406;
  --button-color: #ffdd00;
  --color: #fff;
}

.error-toaster-class {
  --background: #bd362f;
  --button-color: #ffdd00;
  --color: #fff;
}

.loader-css-class {
  --background: #222;
  --spinner-color: #fff;
  color: #fff;
}

.scroll-page {
  position: relative;
  top: 0px !important;
  right: 0;
  // bottom: 50px;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.habit-feedback-modal-css {
  padding: 45% 0 0 0;
  position: absolute;
}

.bg-main {
  background-color: #f6f9fb;
}
ion-header {
  --background: transparent;
}
ion-content {
  --background: none;
}
.button-native {
  background-color:var(--highlight-color);
}

.bg-content {
  //background: url("assets/shapes.svg") !important;
  // background-size: cover !important;
  // background-repeat: no-repeat !important;
  // background-position: center !important;
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, #000 0%, var(--theme-color) 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.daily-steps, .congrats_sec {
  border: 3px solid var(--highlight-color);
}
.bg-screens {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: var(--theme-color);
  background: linear-gradient(
    180deg,
    var(--theme-color) 0%,
    rgba(0, 0, 0, 1) 55%,
    rgba(0, 0, 0, 1) 85%,
    var(--theme-color) 100%
  );
  z-index: 0;
  .outer-circle {
    width: 600px;
    height: 500px;
    border-radius: 50%;
    background: rgb(248, 249, 251);
    background: linear-gradient(90deg, rgba(158, 158, 158, 1) 0%, rgba(80, 81, 82, 1) 100%);
    transform: rotate(-45deg);
    position: absolute;
    left: -130px;
    bottom: -10px;
    @media (min-height: 750px) {
      width: 700px;
      height: 600px;
      left: -200px;
      bottom: 0px;
    }
    .inner-circle {
      width: 580px;
      height: 450px;
      border-radius: 50%;
      background-color: #ffffff;
      position: absolute;
      left: -10px;
      bottom: 26px;
      @media (min-height: 750px) {
        width: 680px;
        height: 550px;
      }
    }
  }
}
.my-com-tab {
  mat-mdc-tab-header {
    // background: #03488d;
    margin-bottom: 25px;
    position: fixed;
    width: 100%;
    left: 0px;
    z-index: 100;
  }
  .mat-mdc-tab-body-wrapper {
    margin: 70px 0 40px;
    // padding-bottom: 10px;
    @media (max-width: 768px){
      margin: 70px 0 0px!important;
    }
  }
  .mat-mdc-checkbox-inner-container {
    margin-top: 10px;
  }
  .mat-mdc-checkbox-label {
    white-space: normal;
    margin-left: 10px;
  }
  .mat-mdc-checkbox-indeterminate.mat-mdc-accent .mat-mdc-checkbox-background,
  .mat-mdc-checkbox-checked.mat-mdc-accent .mat-mdc-checkbox-background {
    background: #018466;
  }
}
.my-com-tab2 {
  mat-mdc-tab-header {
    z-index: 999;
    background: #fff !important;
    width: calc(100% - 32px);
    margin: 55px auto;
    left: 16px;
  }
  .mat-mdc-tab-label {
    color: #444;
  }
}
.main-nav {
  background-color: #fff;
}
.mobile-view {
  @media (max-width: 500px) {
    display: block;
  }
  display: none;
}
.desktop-view {
  display: block;
  @media (max-width: 500px) {
    display: none;
  }
}
.my-community {
  .mat-mdc-tab-labels {
    display: flex;
    text-align: center;
  }
  .mat-mdc-tab-label {
    min-width: 80px;
    max-width: 90px;
    height: 40px;
  }
  .mat-mdc-tab-header{
    margin-bottom: 20px;
  }
}
.gray-checked {
  label {
    .mat-mdc-checkbox-inner-container {
      background-color: #1e1d1d9e;
    }
  }
}
.custom-modalbox {
  mat-dialog-container {
    height: calc(100vh - 80px);
  }
}

.action-tab {
  .mat-mdc-tab-body-wrapper {

    //min-height: 300px;

    @media (max-width: 768px){
      margin-top: 120px!important;
    }
      max-height: calc(100vh - 350px);
   
    // max-height: 300px;
  }
}

// .my-custom-alert {
//   --background: #e5e5e5;
// }
.alert-button-group.sc-ion-alert-md{
  margin-bottom: 10px;
}
.alert-wrapper {
  .alert-input-group{
    .alert-input-wrapper {
      .alert-button-group {
        display: flex;
        justify-content: center;
        display: flex;
        margin: 0 12px;
        position: relative;
      }
  .web-textarea {
    padding-bottom: 10px;
    line-height: normal;
    border: 1px solid black;
    border-radius: 5px;
    color: black;
    padding: 8px;
    min-height: 70px !important;
  }
    }
  }
  }
.infinite-scroll {
  --color:black;
  font-size: 16px;
}
.alert-message.sc-ion-alert-md, .alert-input-group.sc-ion-alert-md{
  color: #000!important;
}
/* Dark theme styles */
.dark-theme {
  .infinite-scroll {
    --color:white;
  }
  .alert-message.sc-ion-alert-md, .alert-input-group.sc-ion-alert-md{
    color: #fff!important;
  }
  .alert-wrapper {
    --background: black; 
    color: white;
    .alert-title  {
      color: white;
    }
    .alert-sub-title {
      color: white;
      // text-align: center;
      font-weight: 600;
    }
    .alert-textarea {
      color: white;
      background-color: black;
    }
    .alert-message {
      color: white;
    }
    .alert-button-group {
      display: flex;
      justify-content: center;
      display: flex;
      margin: 0 12px;
      position: relative;
    }
    .alert-input-group{
      .alert-input-wrapper {
        .web-textarea  {
          padding-bottom: 10px;
          line-height: normal;
          color: white;
          border: 1px solid white;
          border-radius: 5px;
          padding: 3px 7px;
          min-height: 70px !important;
        }
      }
    }
  }
}

.alert-button-inner{
  text-transform: capitalize!important;
}
.select-icon{
margin-top: 15px!important;
}
/* Light theme styles */
.light-theme {
  // .alert-wrapper {
  //   --background: #e5e5e5;
  // }
  .alert-sub-title {
    color: black;
    // text-align: center;
    font-weight: 600;
  }
}
.header-background {
  backdrop-filter: unset;
}
textarea {
  height: 100%;
}
.ai-text-area-wrap{
  color: #fff;
}
.textarea-wrapper {
  height: 100%;
}

.profile-content {
  margin-top: 30px;
  @media (max-height: 750px) {
    margin-top: 0;
  }
}

// desktop css
.main-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  .top-header {
    width: calc(100vw - 247px);
    height: 77px;
    position: fixed;
    top: 0;
    left: 247px;
    background-color: #fff;
    box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.2);
    padding: 10px 20px;
    z-index: 99;
    font-size: 20px;
    font-weight: 500;
    .link-view-path {
      height: 16px;
      font-family: Roboto;
      font-size: 14px;
      color: var(--theme-color);
      line-height: 16px;
      font-weight: 500;
      text-transform: capitalize;
      cursor: pointer;
    }
  }
  .all-path-wrapper {
    width: 346px;
    height: 100vh;
    overflow-y: auto;
    background: var(--theme-color);
    position: fixed;
    right: -346px;
    top: 0;
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    -ms-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    z-index: 99;
    &.active {
      right: 0;
    }
    .path-headings {
      width: 100%;
      height: 77px;
      padding: 20px;
      h4 {
        font-family: Roboto;
        font-size: 20px;
        color: #ffffff;
        line-height: 24px;
        font-weight: 600;
        text-transform: capitalize;
        margin: 0;
      }
      .back-arrow {
        max-width: 25px;
        width: 100%;
        cursor: pointer;
      }
    }
  }
  .left-sidebar {
    width: 247px;
    height: 100vh;
    overflow-y: auto;
    background: var(--theme-color);
    position: relative;
    z-index: 12;
    .logo-wrapper {
      display: flex;
      justify-content: center;
      text-align: center;
      .logo{
        max-width: 120px;
      max-height: inherit;
      }
    }
    .sidemenu {
      margin-top: 100px;
      ul {
        list-style-type: none;
        padding: 0;
        li {
          list-style-type: none;
          a {
            img {
              max-width: 32px;
              width: 100%;
              margin-right: 15px;
            }
            padding: 15px 30px;
            font-family: Roboto;
            font-size: 16px;
            color: var(--themeheader-color);
            text-decoration: none;
            line-height: 22px;
            text-transform: capitalize;
            font-weight: 600;
            display: flex;
            align-items: center;
            position: relative;
            -webkit-transition: all 0.6s ease-out;
            -moz-transition: all 0.6s ease-out;
            -ms-transition: all 0.6s ease-out;
            -o-transition: all 0.6s ease-out;
            transition: all 0.6s ease-out;
            z-index: 1;
            &:before {
              content: "";
              position: absolute;
              right: 100%;
              top: 0;
              width: 100%;
              height: 100%;
              background-color: #edf4f6;
              z-index: -1;
              opacity: 0;
              -webkit-transition: all 0.6s ease-out;
              -moz-transition: all 0.6s ease-out;
              -ms-transition: all 0.6s ease-out;
              -o-transition: all 0.6s ease-out;
              transition: all 0.6s ease-out;
            }

            &:hover,
            &.active {
              color:var(--theme-color);
              img {
                // filter: invert(54%) sepia(33%) saturate(571%) hue-rotate(153deg) brightness(89%) contrast(91%);
              }
              &:before {
                opacity: 1;
                right: 0;
              }
            }
          }
        }
      }
    }
    .status-bar {
      bottom: 0;
      position: absolute;
      width: 100%;
      .status-bar-box {
        display: inline-block;
        box-shadow: 0px -2px 2px rgb(0 0 0 / 20%);
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        width: 100%;
        background-color: #fff;
        position: relative;
        .status-bar-circle {
          margin: 0 auto;
          position: absolute;
          z-index: 100;
          top: -50px;
          left: 50%;
          transform: translateX(-50%);
          .profile-pic {
            width: 89px;
            height: 89px;
            border-radius: 50%;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              object-fit: cover;
              object-position: center;
            }
          }
        }

        .rank_point {
          margin: 0;
          z-index: 101;
          padding: 10px 0;
          @media (max-height: 670px) {
            padding: 5px 0;
          }
        }
        .link-profile {
          font-family: "Roboto";
          font-size: 12px;
          color: #4f8fab;
          line-height: 16px;
          text-align: center;
          font-weight: 600;
          text-align: center;
          display: block;
          margin-bottom: 10px;
        }
      }
    }
  }
  .main-content-wrap {
    width: calc(100vw - 247px);
    margin-top: 77px;
    padding: 20px;
    height: calc(100vh - 77px);
    overflow-y: auto;
  }
  .main-content-wrap-desktop {
    width: 100vw;
    // margin-top: 77px;
    // padding: 20px;
    height: 100vh;
  }
  .desk-library-wrapper{
    width: 100vw;
    // height: 100vh;
    overflow-y: auto;
    padding: 20px 40px;
  }
}
a:hover {
  text-decoration: none !important;
}

.upload-photo-sheet {
  padding-left: 0px !important;
}
.popover-content{
  box-shadow: 0px 0px 4px 1px #ccc;
}
@media only screen and (min-width: 1024px) {
  ion-backdrop {
    background: transparent;
  }
  .modal-wrapper{
    --min-height: 90%;
    --border-radius:10px;
    //max-height: 85vh !important;
  }
  // .habit-feedback-modal-css{
  //   .modal-wrapper{
  //     height: auto !important;
  //   }
  // } 
}


.comment-box {
  margin: 0 15px 10px 15px;
  border: 1px solid #eee;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 0px -2px rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 0px -2px rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  padding: 1%;
  position: relative;
}
@media (max-width: 768px) {
.comments.cust-comment{
  margin-top: 5px!important;
  .comment-box{
    border-radius: 0 0 0 25px!important;
    border: 1px solid rgba(0, 0, 0, 0.25)!important;
    border-top: 0!important;
    margin-top: 0!important;
    border-right: 0!important;
    box-shadow:none!important;
    // background-color:#EEF1F6;
    background-color: #2d2d2d;
    padding: 5px 10px 15px!important;
  }
  .content{
    padding: 5px 5px 40px!important;
  }
  .social-icons{
    width: 100%!important;
    text-align: left!important;
    // margin-top: 2%!important;
    line-height: 17px;
    margin-right: 0%;
    font-size: 14px!important;
    color:#fff!important;
    padding-left: 44px!important;
    padding-top: 8px!important;
    border: 0 !important;;
    border-top: 1px solid rgba(0, 0, 0, 0.25)!important;
  }
  .username{
    font-weight: 700;
    // color: #494949;
    color: #fff;
    font-size: 14px;
    text-transform: capitalize;
  }
  .text{
    font-size: 16px;
  }
}
}

.desktop{
  .social-icons{
    margin: 0!important;
    width: 100%!important;
    font-size: 12px!important;
    float: none!important;
    display: flex;
    // justify-content: flex-end;
    span{
      margin-right: 5px;
      text-transform: uppercase;
      @media only screen and (min-width: 768px){
        text-transform: capitalize!important;
      }
    }
  }
}
.habit-checkmark {
  display: inline-block!important;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: var(--highlight-color);
  text-align: center;
  color: #fff;
  margin-top: 5px;
  float: right;
  padding: 4px;
  @media only screen and (min-width: 768px){
    display:none;
  }
}
.habit-functions-list::after {
  content: "";
  clear: both;
  display: table;
}
.welldone-points{
  font-size: 20px;
    display: inline-block;
    font-weight: 700;
}
.well-done-wrap{
  border: 1px solid grey;
  padding: 15px;
  box-shadow: 3px 3px 10px rgb(0 0 0 / 30%), 3px 3px 10px rgb(0 0 0 / 30%);
}
.likeS img{
  width: 21px!important;
  height: 20px!important;
  border-radius: 0!important;
}
.weldone-label{
  min-width: 120px;
  font-weight: 700;
  // margin-right: 20px;
  font-size: 16px;
  display: inline-block;
}
// .item-inner {
//   --border: unset;
// }
.mat-mdc-mini-fab.mat-mdc-accent.mat-mdc-button-disabled,
.mat-mdc-mini-fab.mat-mdc-button-disabled.mat-mdc-button-disabled{
  background-color: #606162!important;
  color: #fff!important;
}
@media only screen and (min-width: 992px){
  .community-card-wraper .community-card {
    .labels{
      font-size: 16px!important;
    } 
    .card_body{
      p{
        font-size: 16px!important;
      }
  } 
}

}
.instep-bookmark{
  position: absolute;
  top: -28px;
  width: 48px;
  height: 48px;
  z-index: 10;
}
.instep-bookmark{
  .ion-color-light{
    background-color: transparent!important;
      box-shadow: none!important;
    button.button-native{
      background-color: transparent!important;
      box-shadow: none!important;
    }
  }
  // :host(.fab-button-close-active) .button-inner{
  //   color: #4f8fab;
  // }
}
.date-bg{
  width: 90%;
  margin: 0px auto;
  display: flex;
  padding: 10px 0px;
  justify-content: center;
}
.change-date{
  padding-left: 20px;
  text-align: left !important;
}
.picker{
  flex: auto !important;
  text-align: right;
  font-size: 12px !important;
  font-weight: 600;
  color: #444 !important;
  -ms-flex: 0 0 100px;
    flex: 0 0 100px;
    width: 100px;
    min-width: 100px;
    max-width: 200px;
}
.item .sc-ion-label-md-h,
.item .sc-ion-label-ios-h,
.mat-mdc-checkbox-layout{
  white-space: unset!important;
}
.item .sc-ion-label-md-h{
  padding-right: 12px;
}
.ngx-extended-pdf-viewer,
ngx-extended-pdf-viewer{
    .pdfViewer{
      .page{
         border: 0!important;
      }
  }
  #toolbarViewer, #toolbarContainer{
    background-color: #ebecec;
  }
  #outerContainer .hiddenSmallView, #outerContainer .hiddenSmallView * {
    display:flex !important
}
  #outerContainer .hiddenTinyView,#outerContainer .hiddenTinyView * {
   display:flex !important;
  }
  @media (max-width: 1042px) {
    #editorModeButtons.hiddenTinyView, #outerContainer .hiddenTinyView, #outerContainer .hiddenTinyView *{
      display: inline-block; 
  }
  }
  .next-page {
    svg{
      display:none;

    }

   }
}

.library-quill-links {
  a {
    color: var(--highlight-color);
    text-decoration: underline;
  }
}

.like-comment-wrap{
  padding:  8px 0;
  // font-size: 12px;
  font-size: 14px;
  border-top: 1px solid rgba(255, 255, 255, 0.50);
  border-bottom: 1px solid rgba(255, 255, 255, 0.50);
  justify-content: center;
  font-family: Roboto;
  ul{
    width: 100%;
    padding: 0!important;
    margin: 0!important;
    // justify-content: space-around;
    margin: 0!important;
    justify-content: center;
    li{
      width: 45%;
    }
    .community-like,.comment-text{
   
      font-weight: bold;
    }
  }
  img{
    width: 25px!important;
    height: 25px!important;
  }
}
// @supports ((-webkit-margin-start: 0) or (margin-inline-start: 0)) or (-webkit-margin-start: 0){
// .item-native {
//     padding-left: 0!important;
//     padding-right: 0!important;
//     padding-inline-start:0!important;
// }
// }
// .item-native {
//   padding-left: 0!important;
//   padding-right: 0!important;
//   padding-inline-start:0!important;
// }
// .item-native{
//   padding-inline-end: unset!important;
// }


@keyframes slide {
  100% { right: 0; }
}

.dialog-side-panel {
  position: fixed !important;
  bottom: 0;
  top: 0;
  right: -100vw;
  width: 100vw;
  height: 100%;
  animation: slide 0.4s forwards;
  animation-delay: 0.1s;

  .mat-mdc-dialog-container {
    border-radius: 0;
  }
}
.vertically-center{
  display: flex!important;
  align-items: center!important;
}

.user-img-rply{
  .avatar{
    width: 30px!important;
    height: 30px!important;
  }
}
.sub-rply-community-img{
  .avatar{
    width: 30px!important;
    height: 30px!important;
  }
}
.hide_comment {
  .ng-star-inserted:nth-child(2){
    .commentable{
      background-image: linear-gradient(to bottom, rgba(238,241,246,0), rgba(255,255,255,1))!important;
      height: 58px;
      .reply-text{
        background: linear-gradient(to bottom, #000 0%, #fff 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent
      }
    }
    
  }
  // add css styles now add css styles atleast add one css property
  .comment-box{
   display: none;
    // opacity: 0;
  }
  
}
// .comment-box,
// .comment-textarea,
// .input-group{
//   opacity: 1;
//   transition: 1s linear 1s;
//   animation-name: example;
//   animation-duration: 1s;
// }

.animation-class{
  opacity: 1;
  transition: 2s linear 1s;
  animation-name: example;
  animation-duration: 5s;
}
@keyframes example {
  0%   {opacity: 0}
  
  100%  {opacity: 1;}
}

.alertLogCss{
.alert-message.sc-ion-alert-ios, .alert-input-group.sc-ion-alert-ios {
  font-size: 16px !important;
}
}
.reply-for-web{
  border: 0!important;
  width: 100%;
  ul{
  justify-content: flex-start!important;
  // li{
  //   justify-content: center;
  // }

}
}
.reply-animation-class{
  .commentable{
  background-color: #fff;
  transition: 2s linear 1s;
  animation-name: example1;
  animation-duration: 5s;
  //animation-delay: 0.1s;
}
}
@keyframes example1 {
  0%   {background-color:#fff}
  100%  {background-color:#EEF1F6}
}
.disable_post_btn {
  color:#494949;
  font-weight:bold;

}
.enable_post_btn {
color:#000000;
font-weight:bold;
}
// .post_body {
//   font-size:20pt !important;
//   line-height: 1.3 !important;
//   text-align: left;
// }
// .post_remaining_content {
//    font-size: 17pt !important;
//    line-height: 1.3 !important;
//   text-align: left;
// }
.collapsible {
  overflow: hidden;
}
.community-like-icon {
  font-size: 15px;
}

// .show-more {
//   -webkit-transition: max-height 1s;
//   -moz-transition: max-height 1s;
//   -ms-transition: max-height 1s;
//   -o-transition: max-height 1s;
//   transition: max-height 1s;
//   overflow: hidden;
//   max-height: 0;
// }
// .show-more.show {
//   max-height: 55px;
// }
.has-quill-links{
  img{
    width: 100%!important;
  }
}
.safe--area {
  padding-top: env(safe-area-inset-top) !important;
  padding-bottom: env(safe-area-inset-bottom) !important;
}
.text-bold{
font-weight:bold;
}
.mat-mdc-tab-label.mat-mdc-tab-label-active {
  opacity: 1;
  background-color: transparent !important;
  border-bottom: 2px solid white;
}

.scrollable-content {
  overscroll-behavior-y: contain;
}
.daily-content-screen {
  .onboarding-back-frame.container-blur {
  //position: relative;
  //filter: blur(1px); /* Adjust the blur amount as desired */
  // background-color: lightgrey !important;
  background-color: #ffffff40!important;
  transition: width 2s linear 0.5s;
  // background-color: rgb(48, 47, 47) !important;
  //border-radius: 6px 6px 0px 0px;
  &.dark-theme{
    background-color: rgb(63, 63, 63)!important;
  }
  // @media (min-width: 770px){
  //   // background-color: lightgrey !important;
  //   background-color: #efefef!important;
  // }

}
}

// mobile onbording
.onboarding-back-frame{
&.container-blur{
  background-color: lightgrey !important;
  transition: width 2s linear 0.5s;
  &.dark-theme{
    background-color: rgb(63, 63, 63)!important;
  }
}
}
.container-blur{
    background-color: lightgrey !important;
    transition: width 2s linear 0.5s;
    &.dark-theme{
      background-color: rgb(63, 63, 63)!important;
    }
  }
// desktop 
.desktop-daily-content-screens {
  .container-blur{
    background-color: #efefef!important;
    transition: width 2s linear 0.5s;
    &.dark-theme{
      background-color: rgb(63, 63, 63)!important;
    }
  }
}
.instep-gray-bg-dark-mode, .web-instep-gray-bg-dark-mode{
  .container-blur &.dark-theme {
    background-color: rgb(63, 63, 63)!important;
    transition: width 2s linear 0.5s;
  }
 .chart-percentage{
    fill:#ADADAD !important;
    }
    .option-container{
      color:#ADADAD !important;
    }
}
.onboarding-back-frame
.custom-popover{
  background-color: rgba(0, 0, 0, 0.2)!important;
  ion-backdrop {
    // width: 82%;
    // left: 8%;
    // top: 10.5%;
    // bottom: 12.5%;
    //background-color: rgba(0, 0, 0, 2) !important;
  }
  .popover-content{
    width: 50%;
    margin-left:12%;
  }
}

.force-rank-popover{
  background-color: rgba(0, 0, 0, 0.2)!important;
  .popover-content{
    width: 50%;
  }
}

.custom-modal {
  --ion-modal-enter-duration: 0ms;
  --ion-modal-leave-duration: 0ms;
  // justify-content: end;
  // height: 90%;
  // --background:#1a1a1a;
  @media (min-width: 770px){
    --background:#1a1a1a;
  }
  
}
.custom-alert  {
  --backdrop-opacity: 0.7;
  display: flex !important;
    float: none;
    width: 36%;
    margin-left: auto !important;
    margin-right: auto !important;
    position: initial;
    margin-top: 2%;
    justify-content: center !important;
    text-transform: capitalize !important;
    text-align: center !important;
    border-radius: 4px;
    background-color: var(--theme-color) !important;
    border: 0;
    font-size: medium;
    color: white !important;
    height: fit-content;
    .alert-button-inner{
      justify-content: center !important;
    }
}

.delete-custom-alert {
  --backdrop-opacity: 0.7;
  display: flex !important;
    float: none;
    width: 36%;
    margin-left: auto !important;
    margin-right: auto !important;
    position: initial;
    margin-top: 2%;
    justify-content: center !important;
    text-transform: capitalize !important;
    text-align: center !important;
    border-radius: 4px;
    background-color: var(--highlight-color) !important;
    border: 0;
    font-size: medium;
    color: white !important;
    height: fit-content;
    .alert-button-inner{
      justify-content: center !important;
    }
}
.del-custom-alert{
  .alert-title {
    color: var(--highlight-color) !important;
    
  }
  .alert-message {
    color: black !important;
    margin-bottom:-30px;
  }
}
.custom-alert-textcase{
  text-transform: capitalize;
}
.action-alert-disable {
  // color: rgba(0,0,0,.26) !important;
  // padding: 2px 8px;
  // margin: 5px;
  // height: fit-content;
  // width: 36%;
  //   border-radius: 4px;
  // justify-content: space-between !important;
  // text-transform: capitalize !important;
  // text-align: center !important;
  // background-color: #fff;
  // justify-content: space-between;
  // box-shadow: 1px 1px 1px grey;
  // font-size: 12px;
  // border: 2px solid var(--highlight-color) !important;
  // font-weight: 400; --backdrop-opacity: 0.7;
  display: flex !important;
  float: none;
  width: 36%;
  margin-left: auto !important;
  margin-right: auto !important;
  position: initial;
  margin-bottom:15px !important;
  margin-top: 2%;
  box-shadow: 1px 1px 1px grey;
  justify-content: center !important;
  text-transform: capitalize !important;
  text-align: center !important;
  border-radius: 4px;
  border: 2px solid var(--highlight-color) !important;
  font-size: medium;
  color: rgba(0,0,0,.26) !important;
    height: fit-content;
  .alert-button-inner{
    justify-content: center !important;
  }

}
.action-alert-enable {

  display: flex !important;
  float: none;
  width: 36%;
  margin-left: auto !important;
  margin-right: auto !important;
  position: initial;
  margin-bottom:15px !important;
  margin-top: 2%;
  box-shadow: 1px 1px 1px grey;
  justify-content: center !important;
  text-transform: capitalize !important;
  text-align: center !important;
  border-radius: 4px;
  border: 2px solid var(--highlight-color) !important;
  font-size: medium;
  color: #000 !important;
  height: fit-content;
  .alert-button-inner{
    justify-content: center !important;
  }
}
.alert-button-group{
  justify-content: center!important;
}
.custom-alert-action {
text-align: center !important;
  // ion-alert {
    .alert-message {
      color: black !important;
      margin-top:-2px;
    }
    .alert-button-group {
      display: flex;
      justify-content: space-between;
    }
  // }
}
.custom-modal-size {
  // top: 18%;
  // left: 8%;
  // right: 10%;
  // bottom: 25%;
  padding: 40% 9% 45% 9%;
  box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --border-radius: 15px;
  --backdrop-opacity: 0.25 !important;
  margin: 0px;
  @media (max-width: 768px){
    padding: 30% 9% 35% 9%;
    overflow-y: auto;
  }
}
.custom-alert-give-feedback-modal {
  box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --border-radius: 15px;
  padding: 30% 8% 40px;
  // width: 80vw;
  overflow-y: auto; 
  height: 470px;
}
.mobile-custom-to_do_list-forms {
    --border-radius: 6px;
    --background: transparent;
    --box-shadow: 0 !important;
    padding: 36% 10% 2%;
    --backdrop-opacity: 0.25 !important;
}
.select-path-model-custom{
  
  --background:trasparent;
  --ion-modal-enter-duration: 0ms;
  --ion-modal-leave-duration: 0ms;
  justify-content: flex-end;
  align-items: center;
  right:10px;
  --height: 87%;
  --border-radius: 20px;
  --width:70%;
}
.alert-textarea {
  height:100px;
  overflow-y: auto;
}
.signup-popover{
  .popover-content{
    background-color: #fff !important;
  }
}
.ios-menu{
  padding-left: var(--ion-safe-area-bottom,0);
}

.changeDialog-color{
  mat-dialog-container {
    background:#000 !important;
    color: #fff;
    .alert-dark{
      background:#000 !important;
    color: #fff!important;
    }
  }
}
.routine-cal-wrap{
  .input-wrapper1{
    margin-top: 25px!important;
  }
  .dark-theme{
    .mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above{
      margin-top: -10px;
    }
    .mat-label{
      color: #fff!important;
    }
    .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input{
      color: #fff!important;
    }
    .mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
      padding-right: 0;
      border: 1px solid #fff!important;
  }
  .mat-mdc-form-field-has-icon-suffix.mat-focused{
     .mat-mdc-text-field-wrapper {
      padding-right: 0;
      border: none!important;
  }
  }
}
}
.dark-theme{
mat-slider {
  width: 100%;
  .mat-mdc-slider-track-background{
    background-color:#6c6c6c!important;
  }
}
.mat-mdc-form-field-appearance-fill 
.mat-mdc-form-field-flex{
  // background-color: #212121!important;
  color: #333;
  & ::placeholder {
    color:#868686;
  }
  
  &::-ms-input-placeholder { /* Edge 12 -18 */
    color:#868686;
  }
}
}

// onboarding css
.desktop-view{
  mat-slider {
    width: 100%;
    .mat-mdc-slider-track-background{
      background-color:#6c6c6c!important;
    }
  }
  .mat-mdc-form-field-appearance-fill 
  .mat-mdc-form-field-flex{
    background-color: #212121!important;
    color: #adadad!important;
  }
  }
.changeDialog-color2{
  .mat-mdc-dialog-container{
  background-color:#777777;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 1px 0px 5px 1px #d5d5d5;
  .onboarding-back-frame{
      background-color: #212121;
      color: #adadad;
    }
    .button{
      box-shadow: none;
    }
    .routine_para{
      color: #adadad!important;
    }
    .mat-mdc-form-field-appearance-fill 
    .mat-mdc-form-field-flex{
      background-color: #212121!important;
      color: #adadad!important;
      .mat-mdc-select-value,.prev-arrow-icon{
        color: #adadad!important;
      }
    }
  }
}

.web_feedbackForm_modal {
  --max-width: 23%;
  --max-height: 54%;
  --border-radius: 6px;
  --background: transparent;
  --box-shadow: 0 !important;
  --backdrop-opacity:0.25 !important
}

.web_routine_feedback_modal {
  --max-width: 23%;
  --max-height: 41%;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity:0.25 !important;
  @media (max-width: 768px) {
    --max-width: 80vw;
    --max-height: 30%;
  }
}

ion-checkbox::part(container) {
  border-radius: 6px;
  border: 2px solid #fff !important;
}
.custom-modal .ion-backdrop {
  background-color: rgba(0, 0, 0, 1); /* Fully opaque black */
}

.custom-modal-desktop {
  background-color: rgba(0, 0, 0, 1); /* Fully opaque black */
  --width: 950px;
  // margin-left: -460px;
  
}
.custom-modal-desktop .swiper-pointer-events {
  margin-left: -515px !important;
}

.todo-checkbox {
    border-radius: 6px;
    border: 3px solid #000;
}
.desktop-custom-modal {
  --ion-modal-enter-duration: 0ms;
  --ion-modal-leave-duration: 0ms;
  justify-content: flex-end;
  --height: 90%;
   padding-bottom: 20px;
  padding-right:15px;
  // --min-width:730px;
  --border-radius: 6px;
}
.desktop-cust-leaderboard{
  --ion-modal-enter-duration: 0ms;
  --ion-modal-leave-duration: 0ms;
  justify-content: flex-end;
  --height: 90%;
   padding-bottom: 25px;
  padding-right:15px;
  --width:1000px!important;
  --border-radius: 6px;
  --background:#1a1a1a;
}
.desktop-custom-moda .ion-backdrop {
  background-color: rgba(0, 0, 0, 1); /* Fully opaque black */
}

.desktop_enable_post_btn {
color:#fff;
font-weight:bold;
}
ion-infinite-scroll .infinite-loading {
  color: #fff; 
}
.pointer {
  cursor: pointer;
}
.resend-btn-text {
  color: var(--highlight-color);
  &:hover {
    color: var(--highlight-color);
  }
}

.desktop-library-custom{
  --ion-modal-enter-duration: 0ms;
    --ion-modal-leave-duration: 0ms;
    --backdrop-opacity:0.25 !important;
    justify-content: flex-end;
    --height: 90%;
    padding-bottom: 25px;
    padding-right: 15px;
    --width: 700px!important;
    --border-radius: 6px;
    --background:#1a1a1a;
}

.desktop-profile-custom{
  --ion-modal-enter-duration: 0ms;
    --ion-modal-leave-duration: 0ms;
    justify-content: flex-end;
    --height: 90%;
    padding-bottom: 25px;
    padding-right: 15px;
    --width: 900px!important;
    --border-radius: 6px;
    --background:#1a1a1a;
}

.desktop-todo{
  --ion-modal-enter-duration: 0ms;
    --ion-modal-leave-duration: 0ms;
    justify-content: flex-end;
    --height: 90%;
    padding-bottom: 25px;
    padding-right: 15px;
    --width: 550px!important;
    --border-radius: 6px;
    --background:#1a1a1a;
}
.web-daily-steps-custom-modal {
  --ion-modal-enter-duration: 0ms;
  --ion-modal-leave-duration: 0ms;
  justify-content: center;
  --height: 88%;
  top: 20px;
  --width: 500px !important;
  --border-radius: 10px;
  padding-bottom: 5px;
  --background:#1a1a1a;
  &.dark-theme{
    --background: #212121;
  }
}
.web-action-calender-modal {
  --ion-modal-enter-duration: 0ms;
  --ion-modal-leave-duration: 0ms;
  --height: 450px;
  --backdrop-opacity:0.2 !important;
  --width: 450px !important;
  justify-content: center;
  align-items: center;
  display: flex;
  // --box-shadow: 0 28px 38px rgba(0, 0, 0, 0.4) !important;
  --border-radius: 5px;
  margin: 0px;
}
.routine-cal,.routine-calN{
  --height: 570px;
}
.web-action-calender-modal.dark-theme{
  --background:#000;
  color: #fff;
}
.web-action-routine-modal {
  --ion-modal-enter-duration: 0ms;
  --ion-modal-leave-duration: 0ms;
  --height: 37%;
  --backdrop-opacity:0.2 !important;
  --width: 350px !important;
  justify-content: center;
  align-items: center;
  display: flex;
  --box-shadow: 0 28px 38px rgba(0, 0, 0, 0.4) !important;
  --border-radius: 5px;
  margin: 0px;
}
.web-action-routine-modal.dark-theme{
  --background:#000;
  color: #fff;
}
.alertBackdrop {
  --backdrop-opacity:0.2 !important;
}
.web-closeout-welcome-modal {
  --ion-modal-enter-duration: 0ms;
    --ion-modal-leave-duration: 0ms;
    --height: fit-content;
    --backdrop-opacity: 0.4 !important;
    --width: 420px !important;
    min-width: 360px;
    // /* justify-content: center; */
    // /* align-items: center; */
    // /* display: flex; */
    --box-shadow: 0 28px 38px rgba(0, 0, 0, 0.4) !important;
    --border-radius: 10px;
    --background: #1A1A1A;
    background-image: url("../src/assets/images/firworx.gif")!important;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.alert-button.sc-ion-alert-md{
  background-color: var(--theme-color);
  padding: 5px 10px;
  height: 28px;
  color: #fff;
  min-width: 100px;
  margin: 0 3px!important;
  &.alert-button-role-cancel{
    background-color:transparent!important;
    color: #000;
  }
  .alert-button-inner.sc-ion-alert-md{
    justify-content: center!important;
  }
}
.dark-theme{
  .alert-button.sc-ion-alert-md{
    &.alert-button-role-cancel{
      color: #fff!important;
    }
  }
}
  .routine-date-input::part(icon) {
    opacity: 1;
    margin-top: 16px;
  }

  .web-alert-unlock-custom-modal {
  --ion-modal-enter-duration: 0ms;
  --ion-modal-leave-duration: 0ms;
  justify-content: center;
  --height: 88%;
  top: 20px;
  --width: 500px !important;
  --border-radius: 10px;
  padding-bottom: 5px;
}
.dark-theme-review-modal {
    color: #adadad;
    --background: #212121;
}
#editorFreeTextParamsToolbar, 
#editorInkParamsToolbar{
  color: #555555;
}
#editorFreeTextParamsToolbar{
  inset-inline-end: 36px!important;
}
#editorInkParamsToolbar{
  inset-inline-end: 19px!important;
}
#editor_free_text2_label,
#editor_ink2_label,
#download{
  svg{
    margin: 2px auto!important;
  }
}
ngx-extended-pdf-viewer #outerContainer .hiddenTinyView *{
  justify-content: center!important;
}
ngx-extended-pdf-viewer .editorParamsToolbarContainer>.editorParamsSetter
ion-button{
  font-family: Roboto!important;
} 
.desktop-allpath{
    --ion-modal-enter-duration: 0ms;
      --ion-modal-leave-duration: 0ms;
      justify-content: center;
      --height: 88%;
      padding-bottom: 25px;
      padding-right: 15px;
      --width: 78% !important;
      --border-radius: 6px;
      --background:#1a1a1a;
    
}

.desktop-allpath::-webkit-scrollbar {
    display: none;
  }
.mobile-foozi-ai {
  --ion-modal-enter-duration: 0ms;
  --ion-modal-leave-duration: 0ms;
  --background: linear-gradient(#060606 70%, #1A2428),#212121;
}
// .like-hover-img{
//   display: none;
// }
// .like:hover{
//   .like-hover-img{
//     display: block !important;
//   }
//   .like-img{
//     display: none!important;
//   }
// }
.m-foozi-Ai-library-popup{
  --background: linear-gradient(180deg, rgba(32, 32, 32, 0.20) 0%, rgba(32, 32, 32, 0.00) 100%), #121212;
  // --background:rgb(232, 229, 229);
  // background: rgb(232, 229, 229);
  height:100%;
  --height: 100%;
  top: 38%;
  --border-radius:30px 30px 0 0;
  padding-bottom: 5px;

}
.web-foozi-Ai-library-popup{
  --background: linear-gradient(#060606 70%, #1A2428),#212121;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  --height: 50%;
  --width: auto;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  --border-radius: 30px;
  --backdrop-opacity:0.3 !important;
}

.notification-reminder-popup-mobile   {
    --ion-modal-enter-duration: 0ms;
      --ion-modal-leave-duration: 0ms;
      --height: 45%;
      --width: 92% !important;
      justify-content: center;
      align-items: center;
      display: flex;
      --box-shadow: 0 28px 38px rgba(0, 0, 0, 0.4) !important;
      --border-radius: 5px;
      margin: 0px;
}

.notification-reminder-popup-web {
  --ion-modal-enter-duration: 0ms;
  --ion-modal-leave-duration: 0ms;
  --height: 36%;
  border-radius: 10px;
  --backdrop-opacity:0.55!important;
  --width: 410px !important;
  justify-content: center;
  align-items: center;
  // --background: #000;
  display: flex;
  --box-shadow: 0 28px 38px rgba(0, 0, 0, 0.4) !important;
  --border-radius: 5px;
  margin: 0px;
}
.sc-ion-picker-md-h{
&.dark-theme{
  background: rgba(0, 0, 0, 0.5);
.picker-wrapper{
  --background: #2f2f2f;
  color: #fff;
}
}
&.light-theme{
  background: rgba(7, 7, 7,0.5);
}
}
.mat-mdc-form-field-underline{
  display: none;
}
.alert-like-modal {
  --ion-modal-enter-duration: 0ms;
  --ion-modal-leave-duration: 0ms;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: auto;
  outline: 0;
  --width: 225px;
  --background-color: #f9f9f9;
  --height: auto;
  --backdrop-opacity: 0.4 !important;
}

.hide-modal-portrait {
  display:none;
}
.web-action-calender-modal {
  .add-to-calendar-label {
    display:none;
  }
}
.foozi-dynamic-textarea {
  padding-top:8px;
  --padding-top: 0px !important; 
  --padding-end: 0px !important; 
  --padding-bottom: 0px !important;
 margin-bottom:-20px !important;
}
.foozi-dynamic-textarea::first-letter{
  text-transform: capitalize;
}
.web-profile-wrap {
  .user-initials-thumbnail{
    border: 3px solid var(--theme-color)!important;
    width: 42px;
    height: 42px;
  }
}
.user-initials-thumbnail {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
  font-weight: 800;
  // border: 3px solid var(--theme-color);
  //leaderboard
  &.two {
    width: 59px !important;
    height: 59px !important;
    font-size: 16px !important;
    border: 2px solid #fff;
  }

  &.one {
    width: 70px !important;
    height: 70px !important;
    font-size: 18px !important;
    border: 2px solid #fff;
  }

  &.three {
    width: 49px !important;
    height: 49px !important;
    font-size: 14px !important;
    border: 2px solid #fff;
  }

  &.two-stats {
    width: 69px !important;
    height: 69px !important;
    font-size: 17px !important;
    border: 2px solid #fff;
  }

  &.one-stats {
    width: 87px !important;
    height: 87px !important;
    font-size: 18px !important;
    border: 2px solid #fff;
  }

  &.three-stats {
    width: 59px !important;
    height: 59px !important;
    font-size: 16px !important;
    border: 2px solid #fff;
  }
  &.leaderboard-stats {
    width: 27px !important;
    height: 27px !important;
    font-size: 13px !important;
  }
  &.web-user-imgage {
    width: 50px;
    height: 50px;
    background-color: #dadada;
    border-radius: 50%;
    font-size: 28px !important;
  }
  &.mobile-user-image {
    font-size: 28px !important;
    width: 78px;
    height: 78px;
  }
  &.desktop-leaderboard-profile-pic {
    width: 59px;
    height: 59px;
    font-size: 18px !important;
  }
  &.edit-profile-pic {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    font-size: 28px !important;
  }
  &.instep-question-profile {
    width: 35px;
    height: 35px;
    font-size: 16px !important;
  }
  &.textarea-profile-pic {
    width:32px;
    height:32px;
    font-size:14px;
  }
}
.user-initials-thumbnail-likes{
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 10px;
    font-weight: 700;
}
.web-routine-loop-modal {
  --ion-modal-enter-duration: 0ms;
  --ion-modal-leave-duration: 0ms;
  --height: 35%;
  --backdrop-opacity: 0.2 !important;
  --width: 270px !important;
  justify-content: center;
  align-items: center;
  display: flex;
  --box-shadow: 0 28px 38px rgba(0, 0, 0, 0.4) !important;
  --border-radius: 5px;
  margin: 0px;
}

.web-routine-loop-modal.dark-theme {
  --background: #000;
  color: #fff;
}
.all-paths-header-bg,.community-page, .mob-leaderboard, .user-login-mobile{
.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #fff!important;
  
}
.mat-mdc-tab-header-pagination-chevron{
  border-color: #fff!important;
}
.mat-mdc-tab .mdc-tab__text-label, .mat-mdc-tab-link .mdc-tab__text-label{
  color: #fff!important;
  font-size: 16px;
}
.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #fff!important;
  --mat-tab-header-active-label-text-color: #fff!important;
  --mat-tab-header-active-ripple-color: #fff!important;
  --mat-tab-header-inactive-ripple-color: #fff!important;
  --mat-tab-header-active-focus-label-text-color: #fff!important;
  --mat-tab-header-active-hover-label-text-color: #fff!important;
  --mat-tab-header-active-focus-indicator-color: #fff!important;
  --mat-tab-header-active-hover-indicator-color: #fff!important;
}
}
.example-form-field{
  .mdc-text-field--filled:not(.mdc-text-field--disabled){
  background-color: #fff!important;
  }
}
.has-quill-links {
  .ql-video{
    height: 40vh!important;
  }
}
.mat-mdc-dialog-title{
  line-height: 24px!important;
}
.alert-wrapper{
  padding:24px 24px 20px;
  min-width: 390px!important;
}
@media (max-width: 768px) {
  .alert-wrapper{
    min-width: 80vw!important;
  }
  .alert-wrapper.sc-ion-alert-ios {
    border-radius: 6px;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 24px;
    overflow: hidden;
}
.alert-sub-title.sc-ion-alert-ios{
  color: #000;
}
}
.alert-not-committed
{
  text-align: left;
   .alert-button{
  background: var(--theme-color)!important;
  color: #fff;
  border-radius: 6px;
}
}
.alert-sub-title{
  text-align: left;
}
.alert-button.sc-ion-alert-ios{
  border-top: 0;
}
.alert-commite-btn{
  background: var(--theme-color)!important;
  color: #fff!important;
  font-size: 14px;
  border-radius: 6px!important;
  --border-radius:6px;
}
.habit-alert{
  .alert-message{
    text-align: left!important;
  }
  .custom-yes-alert, .alert-ok-btn{
    background: var(--theme-color);
    color: #fff;
    font-size: 14px;
    border-radius: 6px;
  }
  .custom-cancle-btn{
    background: transparent;
    color: #000!important;
    font-size: 14px;
  }
}
.dark-theme{
  .custom-cancle-btn, .alert-edit{
    color: #fff!important;
  }
}
.alert-message, .alert-sub-title{
  text-align: left!important;
  font-size: 16px!important;
  padding: 0!important;
  margin: 15px 0!important;
}
 h2.alert-title{
  font-size: 22px!important;
  font-weight: bold;
  text-align: left!important;
}
.alert-ok-btn, .custom-alert-commite{
  background-color: var(--theme-color)!important;
  color: #fff!important;
  font-size: 14px;
  border-radius: 6px!important;
}
.alert-edit{
  background-color: transparent!important;
  color: #000!important;
  font-size: 14px;
}
.mat-mdc-button .mat-mdc-focus-indicator,
.mat-mdc-unelevated-button .mat-mdc-focus-indicator,
.mat-mdc-raised-button .mat-mdc-focus-indicator,
.mat-mdc-outlined-button .mat-mdc-focus-indicator, .mat-mdc-button .mat-mdc-button-touch-target,
.mat-mdc-unelevated-button .mat-mdc-button-touch-target,
.mat-mdc-raised-button .mat-mdc-button-touch-target,
.mat-mdc-outlined-button .mat-mdc-button-touch-target {
  position: relative !important;
}

.reopt-habit-level-modal {
  --ion-modal-enter-duration: 0ms;
    --ion-modal-leave-duration: 0ms;
    --height: 61%;
    --backdrop-opacity: 0.2 !important;
    --width: 375px !important;
    justify-content: center;
    align-items: center;
    display: flex;
    --box-shadow: 0 28px 38px rgba(0, 0, 0, 0.4) !important;
    --border-radius: 5px;
    margin: 0px;
    @media (max-width: 768px) {
      --width:80vw!important;
      --height: 66%;
      max-height: 686px;
      margin: 0;
    }
}
.alert-head{
  padding: 0!important;
}
.alert-ok-btn, .custom-alert-commite{
  max-width: 150px;
}
.confirm-commitment{
--width:80vw;
height:70%;
max-height: 480px;
}
.dynamic-modal{
  z-index: 999!important;
}
.alert-continue{
  background-color: var(--theme-color)!important;
  color: #fff!important;
  border-radius: 6px !important;
}
.weekendCommitment{
  max-width: 390px!important;
  @media (max-width: 768px){
    max-width: 80vw!important;
  }
}
.note-area-border{
  border: 1px solid var(--theme-color) !important;
  padding: 10px !important;
}
// .alert-message{
//   text-transform: lowercase;
//   &::first-letter{
//     text-transform: uppercase!important;
//   }
// }